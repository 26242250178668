<p-table #dt [value]="zawodnicy" dataKey="id" styleClass="ui-table-customers" [rowHover]="true" [style]="{margin: 'auto', width:'500px'}"
         [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
         [paginator]="true" currentPageReportTemplate="Pierwsze {first} do {last} z {totalRecords} zapisów"
         [filterDelay]="0" [globalFilterFields]="['nick','zawodnik']">
  <ng-template pTemplate="caption">
    Lista zawodników BBO
    <div class="ui-table-globalfilter-container">
      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Wyszukaj" />
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="nick">Nick <p-sortIcon field="nick"></p-sortIcon></th>
      <th pSortableColumn="zawodnik">Zawodnik <p-sortIcon field="zawodnik"></p-sortIcon></th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-bboZawodnik>
    <tr class="ui-selectable-row">

      <td>
        <span class="ui-column-title"></span>
        {{bboZawodnik.nick}}
      </td>
      <td>
        <span class="ui-column-title"></span>
        <span style="vertical-align: middle; margin-left: .5em">{{bboZawodnik.zawodnik}}</span>
      </td>

    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8" style="text-align:left">No customers found.</td>
    </tr>
  </ng-template>
</p-table>
