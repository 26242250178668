import {Component, OnInit, ViewChild} from '@angular/core';
import {BboZawodnik} from '../../classess/bbo-zawodnik';
import {BboZawodnikService} from '../../services/bbo-zawodnik.service';

@Component({
  selector: 'app-zapisy',
  templateUrl: './zapisy.component.html',
  styleUrls: ['./zapisy.component.css']
})
export class ZapisyComponent implements OnInit {
  zawodnicy: BboZawodnik[];

  constructor(private bboZawodnikService: BboZawodnikService) { }

  ngOnInit() {
    console.log('xxx');
    this.getZawodnicy();
  }

  onDateSelect(value) {
   // this.zawodnicy.filter(this.formatDate(value), 'date', 'equals')
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  onRepresentativeChange(event) {
 //  this.zawodnicy.filter(event.value, 'representative', 'in')
  }

  getZawodnicy(): void {
    this.bboZawodnikService.getAll().subscribe(
      (res: BboZawodnik[]) => {
        this.zawodnicy = res;
        console.log(this.zawodnicy);
       // this.dataSource = new MatTableDataSource(this.zawodnicy);
       // this.dataSource.sort = this.sort;
      },
      (err) => {
        this.zawodnicy = err;
      }
    );
  }
}
