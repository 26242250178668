import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BboZawodnik} from '../classess/bbo-zawodnik';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Zawodnik} from '../classess/zawodnik';
import {Wiadomosc} from '../classess/widomosc';
import {Turniej} from '../classess/turniej';
import {WynikiTurnieju} from '../classess/wynikiturnieju';


@Injectable({
  providedIn: 'root'
})
export class WynikiTurniejuService {

  path = 'http://ozbs.olsztyn.pl/services';
  wynikiTurnieju: WynikiTurnieju;
  baseUrl = 'https://ozbs.olsztyn.pl/services/wynki_turnieju_bbo.php?id=';

  constructor(private http: HttpClient) { }

  getAll(nr: number): Observable<WynikiTurnieju> {
    return this.http.get(`${this.baseUrl}${nr}`).pipe(
      map((res) => {
        this.wynikiTurnieju = res['data'];
        return this.wynikiTurnieju;
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError('Error! something went wrong.');
  }

}
