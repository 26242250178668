import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BboZawodnik} from '../classess/bbo-zawodnik';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Zawodnik} from '../classess/zawodnik';
import {Wiadomosc} from '../classess/widomosc';
import {Turniej} from '../classess/turniej';


@Injectable({
  providedIn: 'root'
})
export class LokalneTurniejeService {

  path = 'http://ozbs.olsztyn.pl/services';
  turnieje: Turniej[];
  baseUrl = 'https://ozbs.olsztyn.pl/services/turniejelokalne.php?nr=';

  constructor(private http: HttpClient) { }

  getAll(nr: number): Observable<Turniej[]> {
    return this.http.get(`${this.baseUrl}${nr}`).pipe(
      map((res) => {
        this.turnieje = res['data'];
        return this.turnieje;
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError('Error! something went wrong.');
  }

}
