import { Component, OnInit } from '@angular/core';
import {Lokalneturnieje} from '../../classess/lokalneturnieje';
import {Wiadomosc} from '../../classess/widomosc';
import {WiadomoscService} from '../../services/wiadomosc.service';
import {LokalneTurniejeService} from '../../services/lokalneturnieje.service';
import {Turniej} from '../../classess/turniej';

@Component({
  selector: 'app-wynkilokalne',
  templateUrl: './wynkilokalne.component.html',
  styleUrls: ['./wynkilokalne.component.css']
})
export class WynkilokalneComponent implements OnInit {

  lokalneturnieje: Lokalneturnieje[] = [];

  constructor(private lokalneTurniejeService: LokalneTurniejeService) { }

  ngOnInit(): void {
    this.lokalneturnieje.push({nazwa: 'Cykl pn', turnieje: []});
    this.lokalneturnieje.push({nazwa: 'Sr-pt', turnieje: []});
    this.lokalneturnieje.push({nazwa: 'Popularne', turnieje: []});
    this.odczytajTurnieje(0,5);
    this.odczytajTurnieje(1, 7);
    this.odczytajTurnieje(2, 4);

    console.log('turniejelokalne', this.lokalneturnieje);
  }

  odczytajTurnieje(pidx: number, pnr: number): void {
    let t: Turniej[] = [];
    this.lokalneTurniejeService.getAll(pnr).subscribe(
      (res: Turniej[]) => {
        console.log('xxx', res);
        t = res;
        this.lokalneturnieje[pidx].turnieje = res;
        console.log('yyy', t);
      },
    );
  }
}


