import {Component, Input, OnInit} from '@angular/core';
import {Turniej} from '../../../classess/turniej';
import {Lokalneturnieje} from '../../../classess/lokalneturnieje';

@Component({
  selector: 'app-wynikilokalnego',
  templateUrl: './wynikilokalnego.component.html',
  styleUrls: ['./wynikilokalnego.component.css']
})
export class WynikilokalnegoComponent implements OnInit {
  turnieja: Lokalneturnieje;

  @Input()
  set turniej(value) {
    this.turnieja = value;
    console.log('turniejlokalny', this.turnieja);
  }

  constructor() { }

  ngOnInit(): void {

  }

}
