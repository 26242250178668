<p-table #dt [columns]="cols" [value]="cezar" dataKey="id" styleClass="ui-table-customers" [rowHover]="true" [style]="{margin: 'auto', width:'1100px'} " [autoLayout]="true">

  <ng-template pTemplate="caption">
    <h2>Kartoteka zawodników</h2>
    <div class="ui-table-globalfilter-container">
      <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Wyszukaj" />
    </div>
    <div class="p-grid">
      <span class="p-col-4"><p-radioButton name="group1" value="Option 1" label="Wszyscy  " inputId="opt1" ></p-radioButton></span>
      <span class="p-col-4"><p-radioButton name="group1" value="Option 2" label="Zrzeszeni  " inputId="opt2"></p-radioButton></span>
      <span class="p-col-4"><p-radioButton name="group1" value="Option 3" label="Niezrzeszeni" inputId="opt3"></p-radioButton></span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of cols" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <span *ngIf="col.type == typKolumnyTabelaEnum.STRING">
          {{rowData[col.field]}}
        </span>
        <span *ngIf="col.type === typKolumnyTabelaEnum.NUMBER" class="text-right">
          {{rowData[col.field] | number}}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8" style="text-align:left">Brak zawodników.</td>
    </tr>
  </ng-template>
</p-table>
