import { Component, OnInit } from '@angular/core';
import {Wiadomosc} from '../../classess/widomosc';
import {WiadomoscService} from '../../services/wiadomosc.service';
import {WynikiTurnieju} from '../../classess/wynikiturnieju';
import {WynikiTurniejuService} from '../../services/wyniki.turnieju.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-wyniki-bbo',
  templateUrl: './wyniki-bbo.component.html',
  styleUrls: ['./wyniki-bbo.component.css']
})
export class WynikiBBOComponent implements OnInit {

  wynikiTurnieju: WynikiTurnieju;
  id: number;

  constructor(private wynikiTurniejuService: WynikiTurniejuService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log('turniej1', this.id);
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.getTurniej();
    });
    console.log(this.id);
  }

  getTurniej(): void {
    this.wynikiTurniejuService.getAll(this.id).subscribe(
      (res: WynikiTurnieju) => {
        this.wynikiTurnieju = res;
        console.log('turniej', this.wynikiTurnieju);
      },
      (err) => {
        this.wynikiTurnieju = err;
      }
    );
  }

  navigateTo(nick: any) {
    document.location.href = `https://www.bridgebase.com/myhands/hands.php?tourney=${this.wynikiTurnieju.link}-&username=${nick}`;

  }
}
