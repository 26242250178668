<p-dropdown [options]="cities1" [(ngModel)]="selectedCity1"></p-dropdown>

<p-dropdown [options]="cities2" [(ngModel)]="selectedCity2" optionLabel="name"></p-dropdown>

<button pButton type="button" icon="pi pi-check" iconPos="left"></button>
<p-button label="Click" icon="pi pi-check" iconPos="left"></p-button>

<h3>Severity Buttons</h3>
<button pButton type="button" label="Primary"></button>
<button pButton type="button" label="Secondary" class="ui-button-secondary"></button>
<button pButton type="button" label="Success" class="ui-button-success"></button>
<button pButton type="button" label="Info" class="ui-button-info"></button>
<button pButton type="button" label="Warning" class="ui-button-warning"></button>
<button pButton type="button" label="Danger" class="ui-button-danger"></button>

<h3>Virtual Scroll (10000 Items)</h3>
<p-dropdown [options]="items" [(ngModel)]="item" placeholder="Select Item" [virtualScroll]="true" [itemSize]="31" [filter]="false"></p-dropdown>
<p-calendar [(ngModel)]="value"></p-calendar>



