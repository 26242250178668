import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {FormsModule} from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import { ZapisyComponent } from './components/zapisy/zapisy.component';
import {CardModule, RadioButtonModule, TableModule} from 'primeng';
import { HttpClientModule } from '@angular/common/http';
import { KomponentyComponent } from './components/komponenty/komponenty.component';
import { WiadomosciComponent } from './components/wiadomosci/wiadomosci.component';
import { WiadomoscComponent } from './components/wiadomosci/wiadomosc/wiadomosc.component';
import { WynkilokalneComponent } from './components/wynkilokalne/wynkilokalne.component';
import { WynikilokalnegoComponent } from './components/wynkilokalne/wynikilokalnego/wynikilokalnego.component';
import { WynikiBBOComponent } from './components/wyniki-bbo/wyniki-bbo.component';
import { CezarComponent } from './components/cezar/cezar.component'

@NgModule({
  declarations: [
    AppComponent,
    ZapisyComponent,
    KomponentyComponent,
    WiadomosciComponent,
    WiadomoscComponent,
    WynkilokalneComponent,
    WynikilokalnegoComponent,
    WynikiBBOComponent,
    CezarComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DropdownModule,
        FormsModule,
        ButtonModule,
        CalendarModule,
        BrowserAnimationsModule,
        TableModule,
        HttpClientModule,
        CardModule,
        RadioButtonModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
