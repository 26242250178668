import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BboZawodnik} from '../classess/bbo-zawodnik';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BboZawodnikService {

  bboZawodnicy: BboZawodnik[];
  baseUrl = 'https://ozbs.olsztyn.pl/services';

  constructor(private http: HttpClient) { }

  getAll(): Observable<BboZawodnik[]> {
    return this.http.get(`${this.baseUrl}/bbo_zawodnicy.php`).pipe(
      map((res) => {
        this.bboZawodnicy = res['data'];
        return this.bboZawodnicy;
      }),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError('Error! something went wrong.');
  }

}
