<p-table #dt [value]="wynikiTurnieju.pary" dataKey="id" styleClass="ui-table-customers" [rowHover]="true" [style]="{margin: 'auto', width:'500px'}" [autoLayout]="true">

  <ng-template pTemplate="caption">
    Wyniki turnieju {{wynikiTurnieju.nazwa}} {{wynikiTurnieju.data}}
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Msc</th>
      <th>Zawodnik</th>
      <th>%</th>
      <th>Pdf</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-para>
    <tr class="ui-selectable-row" (click)="navigateTo(para.nick)">

      <td>
        <span class="ui-column-title"></span>
        {{para.miejsce}}
      </td>
      <td>
        <span class="ui-column-title"></span>
        <span style="vertical-align: middle; margin-left: .5em">{{para.zawodnik1}} - {{para.zawodnik2}}</span>
      </td>
      <td>
        <span class="ui-column-title"></span>
        <span style="vertical-align: middle; margin-left: .5em">{{para.procent}}</span>
      </td>
      <td>
        <span class="ui-column-title"></span>
        <span style="vertical-align: middle; margin-left: .5em">{{para.pdf}}</span>
      </td>

    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8" style="text-align:left">No customers found.</td>
    </tr>
  </ng-template>
</p-table>
