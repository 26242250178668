import { Component, OnInit } from '@angular/core';
import {BboZawodnik} from '../../classess/bbo-zawodnik';
import {WiadomoscService} from '../../services/wiadomosc.service';
import {Wiadomosc} from '../../classess/widomosc';

@Component({
  selector: 'app-wiadomosci',
  templateUrl: './wiadomosci.component.html',
  styleUrls: ['./wiadomosci.component.css']
})
export class WiadomosciComponent implements OnInit {
  wiadomosci: Wiadomosc[];

  constructor(private wiadomoscService: WiadomoscService) { }

  ngOnInit(): void {
    this.getWiadomosci();
  }

  getWiadomosci(): void {
    this.wiadomoscService.getAll().subscribe(
      (res: Wiadomosc[]) => {
        this.wiadomosci = res;
        console.log('wiadomosc', this.wiadomosci);
       },
      (err) => {
        this.wiadomosci = err;
      }
    );
  }

}
