test
<nav>
  <a routerLink="/">Home </a>
  <a routerLink="/komponenty">Komponenty </a>
  <a routerLink="/lista">Lista</a>
  <a routerLink="/wiadomosci">Wiadomości</a>
  <a routerLink="/wyniki">Wyniki lokalne</a>
  <a routerLink="/cezar">Kartoteka</a>
</nav>
test
<router-outlet></router-outlet>
