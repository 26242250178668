<div>
{{turnieja.nazwa}}
<ul>
  <li *ngFor="let t of turnieja.turnieje">
    <a href="wynikiBBO/{{t.id}}">{{t.data}}</a>
  </li>
  <li>
  <a href="t.id">PDF</a>
  </li>
</ul>
</div>
